<template>
  <div id="Name">
    <van-nav-bar
      :fixed="true"
      placeholder
      :title="$t('My.PersonalInformation.Name.ModifyNickname')"
      left-arrow
      @click-left="onClickLeft"
    />
    <van-notice-bar color="#333" background="#eee">
      {{ $t('My.PersonalInformation.Name.title') }}
    </van-notice-bar>
    <van-field
      label-align="right"
      label-width="7.5rem"
      colon
      v-model="Name"
      :label="$t('My.PersonalInformation.Name.name')"
      :placeholder="$t('My.PersonalInformation.Name.Please')"
    />
    <div class="button-confirm">
      <van-button size="normal" type="default" block @click="ButtomEvent()">{{
        $t('My.PersonalInformation.Name.Modify')
      }}</van-button>
    </div>
  </div>
</template>

<script>
import { REQUEST_API } from '@/http/api' // 引入封装的axios
import { getUserInfoNew } from '@/api'
import {
  Row,
  Col,
  Icon,
  Cell,
  GridItem,
  Grid,
  NavBar,
  ActionSheet,
  Button,
  Image,
  NoticeBar,
  Field,
  Toast,
} from 'vant'

export default {
  name: 'Name',
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [GridItem.name]: GridItem,
    [Grid.name]: Grid,
    [NavBar.name]: NavBar,
    [ActionSheet.name]: ActionSheet,
    [Button.name]: Button,
    [Image.name]: Image,
    [NoticeBar.name]: NoticeBar,
    [Field.name]: Field,
    [Toast.name]: Toast,
  },
  data() {
    return {
      title: "$t{'My.PersonalInformation.Name.title'}",
      Name: '',
      userInfo: {}
    }
  },
  methods: {
    // 返回上一个路由
    onClickLeft() {
      this.$router.go(-1)
    },
    //  提交的
    ButtomEvent() {
      if (this.Name != '') {
        Toast.loading({
          forbidClick: true,
          duration: 0,
        })
        // let form = {
        //   cmd: '200006',
        //   nickname: this.Name,
        //   uid: this.module.UIDEvent(),
        // }
        this.$api.editUserInfo({username: this.Name},res => {
          getUserInfoNew();
          Toast.clear();
          this.$router.go(-1)
        },err => {
          Toast.clear();
          this.$toast(err)
        })
        // REQUEST_API(form)
        //   .then((r) => {
        //     console.log(r.data.sc)
        //     if (r.data.sc == 0) {
        //       Toast(this.$t('Hint.success'))
        //       let DataArr = this.module.PersonalEvnet()
        //       DataArr[1].data.player.nickname = this.Name
        //       localStorage.setItem('user-info', JSON.stringify(DataArr))
        //       this.$router.go(-1)
        //     } else {
        //       Toast(this.$t('Hint.failed'))
        //     }
        //   })
        //   .catch((e) => {
        //     console.log(e)
        //   })
      } else {
        Toast(this.$t('My.PhoneNew'))
      }
    },
    getUserInfo(){
      Toast.loading({
        forbidClick: true,
        duration: 0,
      })
      this.$api.getUserInfo(res => {
        this.Name = res.username;
        this.userInfo = res;
        Toast.clear();
      },err => {
        Toast.clear();
      })
    }
  },
  created() {
    this.getUserInfo();
    // this.Name = this.module.PersonalEvnet()[1].data.player.nickname
  },
}
</script>

<style lang="less">
#Name {
  width: 100%;
  height: 100%;
  .van-cell {
    margin-bottom: 1.5625rem;
    .van-cell__title {
      > span {
        font-size: 0.875rem;
      }
    }
  }
  .button-confirm {
    width: 100%;
    .van-button {
      width: 90%;
      margin: 0 auto;
      border-radius: 0.5rem;
      background-color: #2f3848;
      color: #ffffff;
    }
  }
}
</style>